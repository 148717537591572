<template>
  <u-form
    :state="internalValue"
    :schema="schema"
    class="space-y-4"
  >
    <dynamic-form-field
      v-for="[key] in useDynamicFormFieldSort(input)"
      :key="key"
      v-model="internalValue[key]"
      :field-key="key"
      :input="input"
    />
    <slot />
  </u-form>
</template>

<script lang="ts" setup>
import type useForm from '@tarcltd/form-vue'
import { deepEqual } from 'fast-equals'

const props = defineProps<{
  modelValue: ReturnType<typeof useForm>['state']
  schema: ReturnType<typeof useForm>['schema']
  input: ReturnType<typeof useForm>['input']
}>()
const emit = defineEmits(['update:modelValue'])
const internalValue = computed({
  get: () => props.modelValue,
  set(value) {
    if (!deepEqual(value, props.modelValue)) {
      emit('update:modelValue', value)
    }
  },
})
</script>
